import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import { useDisclosure } from '@chakra-ui/hooks'

interface ModalContextProps {
    openModal: () =>  void;
    closeModal: () => void;
    isModalOpen: () => boolean;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps)

export function VideoModalProvider ({ children }: { children: React.ReactNode }) {
    const modal = useVideoModalDisclosure()
    return (
        <ModalContext.Provider value={modal}>
            {children}
        </ModalContext.Provider>
    )
}
VideoModalProvider.propTypes = {
    children: PropTypes.element.isRequired
}
// Hook for child components to get the modal object ...
// ... and re-render when it changes.
export const useVideoModal = () => {
    return useContext(ModalContext)
}
// Provider hook that creates modal object and handles state
function useVideoModalDisclosure () {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const isModalOpen = () => {
        return isOpen
    }
    const closeModal = () => {
        return onClose()
    }
    const openModal = () => {
        return onOpen()
    }
    return {
        openModal,
        closeModal,
        isModalOpen
    }
}