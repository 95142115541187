import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import {VideoStateProvider} from './src/providers/video-state-provider'
import {VideoModalProvider} from './src/providers/video-modal-provider'
import { HeaderHeightProvider } from './src/providers/header-height-provider'
// Import your extended theme from a location in `./src`
import theme from './src/theme'

export const wrapRootElement = ({ element }) => (
    // Or ChakraBaseProvider if you only want to compile the default Chakra theme tokens
    <ChakraProvider theme={theme}>
        <HeaderHeightProvider>
            <VideoModalProvider>
                <VideoStateProvider>
                    {element}
                </VideoStateProvider>
            </VideoModalProvider>
        </HeaderHeightProvider>
    </ChakraProvider>
);
