import React, { createContext, useContext, useState, VFC } from "react";

const VideoStateContext = createContext(
    {} as {
        start: number;
        setStart: React.Dispatch<React.SetStateAction<number>>;
        videoId: string;
        setVideoId: React.Dispatch<React.SetStateAction<string>>;
    }
);

// type propsType = {
//     chidren: React.ReactElement;
// }

export const VideoStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [start, setStart] = useState(0);
    const [videoId, setVideoId] = useState('');
    return (
        <VideoStateContext.Provider value={{ start, setStart, videoId, setVideoId }}>
            {children}
        </VideoStateContext.Provider>
    );
}


export const useVideoState = () => {
    const context = useContext(VideoStateContext);
    if (!context) {
        throw new Error('useVideoState must be used within a VideoStateProvider');
    }
    return context;
}
