import { extendTheme, position, ring } from "@chakra-ui/react";

// カスタムテーマの設定
const theme = extendTheme({
  colors: {
    // カスタムカラーの設定
    brand: {
      100: "#f7c6c7",
      200: "#f08283",
      300: "#e84142",
      400: "#d1222a",
      500: "#ba1216",
      600: "#9c0f13",
      700: "#7d0d10",
      800: "#5e0a0c",
      900: "#3e0707",
    },
    kgYellow: {
        50: '#fafce1',
        100: '#eff2bb',
        200: '#e5ea94',
        300: '#dae16a',
        400: '#d0d942',
        500: '#d0d942',
        600: '#8e951e',
        700: '#656a13',
        800: '#3d4008',
        900: '#141600',
    },
    kgRed: {
        50: '#ffe9e7',
        100: '#f0cdcc',
        200: '#e39e9c',
        300: '#d67976',
        400: '#d98380',
        500: '#d98380',
        600: '#892c29',
        700: '#631f1c',
        800: '#3d1110',
        900: '#1c0202',
    },
    kgOrange: {
      50: '#fef1e4',
      100: '#f4e8df',
      200: '#debca0',
      300: '#d0a27b',
      400: '#E3C6AE',
      500: '#E3C6AE',
      600: '#84552f',
      700: '#5f3d21',
      800: '#3a2412',
      900: '#190a00',
    },
    kgBlue: {
        50: '#e3f3ff',
        100: '#c2daf0',
        200: '#9ebfe0',
        300: '#7ba6d1',
        400: '#538AC1',
        500: '#538AC1',
        600: '#2d5984',
        700: '#1e4060',
        800: '#0d263c',
        900: '#000e1b',
    }

  },
  fonts: {
    heading: `'Zen Kaku Gothic New', sans-serif`, // ヘッディング用フォント
    body: `'Noto Sans JP', sans-serif`, // 本文用フォント
  },
  sizes: {
    container: {
      // コンテナの幅
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1232px",
      '2xl': "1536px",
      '3xl': "1792px",
    }
  },
  styles: {
    global: {
      // 全体に適用するスタイル
      html: {
        // scrollBehavior: 'smooth',
      },
      body: {
        // bg: "gray.50", // 背景色
        // color: "gray.800", // テキスト色
      },
    },
  },
  // components:
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold", // ボタン全体の太字設定
        fontFamily: "Zen Kaku Gothic New", // ボタン全体のフォント設定
      },
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      variants: {
        // solid: (props: any) => ({
        //   bg: props.colorMode === "dark" ? "brand.500" : "brand.300",
        // }),
      },
    },
  },
});

export default theme;
