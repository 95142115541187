import React, { createContext, useContext, useState, VFC } from "react";

const HeaderHeightContext = createContext(
    {} as {
        height: number;
        heightPx: string;
        setHeight: React.Dispatch<React.SetStateAction<number>>;
    }
);

// type propsType = {
//     chidren: React.ReactElement;
// }

export const HeaderHeightProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [height, setHeight] = useState(71);
    const heightPx = `${height}px`;
    return (
        <HeaderHeightContext.Provider value={{ height, heightPx, setHeight }}>
            {children}
        </HeaderHeightContext.Provider>
    );
}


export const useHeaderHeight = () => {
    const context = useContext(HeaderHeightContext);
    if (!context) {
        throw new Error('useHeaderHeight must be used within a HeaderHeightProvider');
    }
    const heightPx = `${context.height}px`;
    return { ...context, heightPx };
}
